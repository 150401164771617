import request from "../utils/request";

const api = {
  getRegisterCode: ["post", "getRegisterCode"],
  checkUserName: ["post", "unUsername"],
  register: ["post", "register"],
  login: ["post", "login"],
  logout: ["post", "logout"],
  getUpPasswordCode: ["post", "getPasswordCode"],
  validateCode: ["post", "unCode"],
  upPassword: ["post", "upPassword"],
  getUpUserCode: ["post", "getUpUserCode"],
  updateUserPhone: ["post", "updateUserPhone"],
  updateUser: ["post", "updateUser"],
  updateUserPassword: ["post", "updateUserPasswode"],
  realNameAuth: ["post", "realNameAuth"],
  getJobAll: ["post", "getJobAll"],
  getRealNameAuth: ["post", "getRealNameAuth"],
  updateRealNameAuth: ["post", "updateRealNameAuth"]
};

const formApi = {
  uploadFile: ["post", "uploadFile"]
};

function mapApi() {
  const _api = {};
  const keys = Object.keys(api);
  keys.forEach((v, k) => {
    _api[v] = params => {
      const [mathod, path] = api[v];
      return request()
        [mathod](path, params)
        .then(res => res.data);
    };
  });
  return _api;
}

function mapFormApi() {
  const _formApi = {};
  const keys = Object.keys(formApi);
  keys.forEach((v, k) => {
    _formApi[v] = params => {
      const [mathod, path] = formApi[v];
      return request("multipart/form-data")
        [mathod](path, params)
        .then(res => res.data);
    };
  });
  return _formApi;
}

const _api = mapApi();
const _formApi = mapFormApi();

export default _api;
export { _formApi };
// export function getRegisterCode(phone) {
//   return request()
//     .post("getRegisterCode", { phone })
//     .then(res => {
//       return res.data;
//     });
// }

// export function checkUserName(userName) {
//   return request().post();
// }
