import { EDIT_COMPANY_INFO, EDIT_USER_INFO, EDIT_TECH_INFO } from "./contants";

const initialState = {
  company: {},
  user: {},
  techMans: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EDIT_COMPANY_INFO:
      return {
        ...state,
        company: { ...initialState.company, ...action.payload }
      };
    case EDIT_USER_INFO:
      return {
        ...state,
        user: { ...initialState.user, ...action.payload }
      };
    case EDIT_TECH_INFO:
      return { ...state, techMans: action.payload };
    default:
      return state;
  }
};
