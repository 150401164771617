import React from 'react';
import Loadable from 'react-loadable';
import { Redirect } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import BasicLayout from '../layout/BasicLayout';
import UserLayout from '../layout/UserLayout';
// import Api from '../views/Api';
import Loading from '../components/LoadingComponent';

const Routers = ({ route }) => {
  return <div>{renderRoutes(route.routes)}</div>;
};

const AsyncHome = Loadable({
  loader: () => import('../views/Home/index'),
  loading: Loading
});
const AsyncCompany = Loadable({
  loader: () => import('../views/Company/index'),
  loading: Loading
});
const AsyncProduct = Loadable({
  loader: () => import('../views/Product/index'),
  loading: Loading
});
const AsyncNews = Loadable({
  loader: () => import('../views/News/index'),
  loading: Loading
});
const AsyncContact = Loadable({
  loader: () => import('../views/Contact/index'),
  loading: Loading
});

const AsyncJoin = Loadable({
  loader: () => import('../views/Join/index'),
  loading: Loading
});

const AsyncLogin = Loadable({
  loader: () => import('../views/Login/index'),
  loading: Loading
});

const AsyncRegister = Loadable({
  loader: () => import('../views/Register/index'),
  loading: Loading
});

const AsyncUpdatePassword = Loadable({
  loader: () => import('../views/UpdatePassword/index'),
  loading: Loading
});

const AsyncVipCenter = Loadable({
  loader: () => import('../views/VipCenter/index'),
  loading: Loading
});

const AsyncSuccess = Loadable({
  loader: () => import('../views/Success/index'),
  loading: Loading
});

const AsyncUserInfo = Loadable({
  loader: () => import('../views/UserInfo/index'),
  loading: Loading
});

const UpdatePassword = Loadable({
  loader: () => import('../views/UserInfo/UpdatePassword'),
  loading: Loading
});

const AsyncVerified = Loadable({
  loader: () => import('../views/Verified/index'),
  loading: Loading
});

const AsyncAgreement = Loadable({
  loader: () => import('../views/Agreement/index'),
  loading: Loading
});

const AsyncPrivacy = Loadable({
  loader: () => import('../views/Privacy/index'),
  loading: Loading
});

const AsyncEcgDetail = Loadable({
  loader: () => import('../views/EcgDetail/index'),
  loading: Loading
});

const AsyncShare = Loadable({
  loader: () => import('../views/Share/index'),
  loading: Loading
})

const AsyncUSB = Loadable({
  loader: () => import('../views/USB/index'),
  loading: Loading
})

const routes = [
  {
    path: '/user',
    hideInMenu: true,
    component: UserLayout,
    routes: [
      {
        path: '/user',
        exact: true,
        render: () => <Redirect to={'/user/vip-center'} />
      },
      {
        path: '/user/vip-center',
        name: '会员中心',
        component: AsyncVipCenter
      },
      {
        path: '/user/account',
        name: '账户管理',
        component: Routers,
        routes: [
          {
            path: '/user/account/user-info',
            name: '基本资料',
            component: AsyncUserInfo
          },
          {
            path: '/user/account/verified',
            name: '实名认证',
            component: AsyncVerified
          },
          {
            path: '/user/account/announcement',
            name: '通知公告',
            component: AsyncProduct
          },
          {
            path: '/user/account/my-orders',
            name: '已购服务',
            component: AsyncProduct
          },
          {
            path: '/user/account/update-password',
            name: '已购服务',
            hideInUser: true,
            component: UpdatePassword
          }
        ]
      }
    ]
  },
  {
    path: '/agreement',
    hideInMenu: true,
    name: "agreement",
    component: AsyncAgreement
  },
  {
    path: '/privacy',
    hideInMenu: true,
    name: "privacy",
    component: AsyncPrivacy
  },
  {
    path: '/share',
    hideInMenu: true,
    name: "share",
    component: AsyncShare
  },
  {
    path: '/',
    component: BasicLayout,
    hideInUser: true,
    routes: [
      {
        path: '/',
        exact: true,
        render: () => <Redirect to={'/home'} />
      },
      {
        path: '/home',
        name: '首页',
        exact: true,
        component: AsyncHome
      },
      {
        path: '/company',
        name: '公司介绍',
        exact: true,
        component: AsyncCompany
      },
      {
        path: '/products',
        name: '产品',
        component: AsyncProduct
      },
      // {
      // 	path: '/api',
      // 	name: '开放API',
      // 	component: Api
      // },
      {
        path: '/news',
        name: '新闻中心',
        component: AsyncNews
      },
      {
        path: '/contact',
        name: '联系我们',
        component: AsyncContact
      },
      {
        path: '/join',
        name: '招聘',
        component: AsyncJoin
      },
      {
        path: '/login',
        hideInMenu: true,
        name: '登陆',
        component: AsyncLogin
      },
      {
        path: '/register',
        hideInMenu: true,
        name: '登陆',
        component: AsyncRegister
      },
      {
        path: '/forget',
        hideInMenu: true,
        name: '忘记密码',
        component: AsyncUpdatePassword
      },
      {
        path: '/success',
        hideInMenu: true,
        name: '更改成功',
        component: AsyncSuccess
      },
      {
        path: '/ecg-detail',
        hideInMenu: true,
        name: '单导心电',
        component: AsyncEcgDetail
      },
      {
        path: '/usb',
        hideInMenu: true,
        name: 'usb',
        component: AsyncUSB
      }
    ]
  },
];

export default routes;
