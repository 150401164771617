import React from "react";
// import { Spinner } from "reactstrap";

import style from "./loading-component.module.scss";

const MyLoadingComponent = ({ isLoading, error }) => {
  // Handle the loading state
  if (isLoading) {
    return (
      <div className={style.loading}>
        <div className={style.spinner}>Loading...</div>
        {/* <Spinner className={style.spinner} type='grow' color='warning' /> */}
      </div>
    );
  }
  // Handle the error state
  else if (error) {
    return <div>Sorry, there was a problem loading the page.</div>;
  } else {
    return null;
  }
};

export default MyLoadingComponent;
