const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const START_LOGIN = "START_LOGIN";
const LOGIN_FAIL = "LOGIN_FAIL";
const UPDATE_TOKEN = "UPDATE_TOKEN";
const CLEAR_USER_INFO = "CLEAR_USER_INFO";
const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export {
  LOGIN_SUCCESS,
  START_LOGIN,
  LOGIN_FAIL,
  UPDATE_TOKEN,
  CLEAR_USER_INFO,
  UPDATE_USER_INFO
};
