import { EDIT_COMPANY_INFO, EDIT_USER_INFO, EDIT_TECH_INFO } from "./contants";

const editCompanyInfo = company => ({
  type: EDIT_COMPANY_INFO,
  payload: company
});

const editUserInfo = user => ({
  type: EDIT_USER_INFO,
  payload: user
});

const editTechInfo = tech => ({
  type: EDIT_TECH_INFO,
  payload: tech
});

export { editCompanyInfo, editTechInfo, editUserInfo };
