import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  // NavbarText
} from "reactstrap";
// import history from "../utils/history";
import routes from "../routes/index";

import "./navbar.scss";
import homeLogo from "../assets/home-logo.png";

const RenderItem = routes => {
  let menuItems = [];
  routes.forEach((v, k) => {
    if (v.name && !v.hideInMenu) {
      if (!v.routes) {
        const Item = (
          <NavItem key={v.path}>
            <NavLink
              activeClassName='nav-active'
              className='nav-link'
              to={v.path}
            >
              {v.name}
            </NavLink>
          </NavItem>
        );
        menuItems.push(Item);
      } else if (v.routes) {
        const SubItem = (
          <UncontrolledDropdown nav inNavbar key={v.path}>
            <DropdownToggle nav caret>
              {v.name}
            </DropdownToggle>
            <DropdownMenu right>
              {v.routes.map(v => {
                return (
                  <DropdownItem key={v.path}>
                    <NavLink
                      activeClassName='nav-active'
                      className='nav-link'
                      to={v.path}
                    >
                      {v.name}
                    </NavLink>
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
        menuItems.push(SubItem);
      }
    } else if (!v.name && v.routes && !v.hideInMenu) {
      menuItems = RenderItem(v.routes);
    }
  });
  return menuItems;
};

const NavBar = props => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  // const { userInfo } = props;
  // const phone = userInfo && userInfo.phone;

  return (
    <div style={{ position: "relative" }}>
      <div className={"dist"} />
      <div className={"ct"}>
        <Navbar light expand='md'>
          <NavbarBrand>
            <img src={homeLogo} alt='' />
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className='mr-auto tabs' navbar>
              {RenderItem(routes)}
            </Nav>
            {/* <NavbarText>
              {phone ? (
                <div
                  onClick={() => {
                    history.push("/user/vip-center");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {phone}
                </div>
              ) : (
                <>
                  <button
                    style={{ marginRight: 10 }}
                    className='bt'
                    onClick={() => {
                      history.push("/login");
                    }}
                  >
                    登陆
                  </button>
                  <button
                    size='sm'
                    className='bt bt-orange'
                    onClick={() => {
                      history.push("/register");
                    }}
                  >
                    注册
                  </button>
                </>
              )}
            </NavbarText> */}
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.login ? state.login.userInfo.user : {}
  };
};

export default connect(mapStateToProps)(NavBar);
