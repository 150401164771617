import React from "react";
import { renderRoutes } from "react-router-config";
import Header from "./Header";
import Footer from "./Footer";

export default ({ route }) => {
  return (
    <div>
      <Header />
      {renderRoutes(route.routes)}
      <Footer />
    </div>
  );
};
