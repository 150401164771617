import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  START_LOGIN,
  UPDATE_TOKEN,
  CLEAR_USER_INFO,
  UPDATE_USER_INFO
} from "./contants";
import api from "../../../server/api";
import history from "../../../utils/history";

const loginAction = params => {
  return dispatch => {
    dispatch({ type: START_LOGIN });
    api
      .login(params)
      .then(res => {
        if (res.code === 200) {
          dispatch({ type: LOGIN_SUCCESS, payload: res.data });
          history.push("/user/vip-center");
        }
      })
      .catch(e => {
        dispatch({ type: LOGIN_FAIL, payload: e.message });
      });
  };
};

const updateTokenAction = token => ({
  type: UPDATE_TOKEN,
  token
});

const clearUserInfo = () => ({
  type: CLEAR_USER_INFO
});

const updateUserInfo = info => ({
  type: UPDATE_USER_INFO,
  payload: info
});

export { loginAction, updateTokenAction, clearUserInfo, updateUserInfo };
