import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import monitorReducersEnhancer from "./enhancers/monitorReducer";
import loggerMiddleware from "./middleware/logger";
import rootReducer from "./reducer";

const storageConfig = {
  key: "root",
  storage: storage,
  whitelist: ["login"]
};

const myPersistReducer = persistReducer(storageConfig, rootReducer);

const store = (function configureStore(preloadedState) {
  const middlewares = [loggerMiddleware, thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const newCompose = composeWithDevTools || compose;

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = newCompose(...enhancers);

  const store = createStore(
    myPersistReducer,
    preloadedState,
    composedEnhancers
  );

  return store;
})();

export const persistor = persistStore(store);
export default store;
