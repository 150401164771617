import React from "react";
import { Layout, message } from "antd";
import { renderRoutes } from "react-router-config";
import { connect } from "react-redux";
import UserSider from "./UserSider";
import { actionCreators } from "../views/Login/store/index";
import api from "../server/api";
import history from "../utils/history";

import style from "./user-layout.module.scss";

const { Header, Content } = Layout;

class SiderDemo extends React.Component {
  state = {
    collapsed: false
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  handleLogout = () => {
    const { clearnUserInfo, userInfo } = this.props;
    const { token } = userInfo;
    api
      .logout({ token })
      .then(res => {
        if (res.code === 200) {
          clearnUserInfo();
          message.success("退出成功");
          history.push("/login");
        }
      })
      .catch(e => message.error(e.message));
  };

  render() {
    const { route, userInfo } = this.props;
    const phone = userInfo.user && userInfo.user.phone;
    return (
      <Layout style={{ height: "100vh" }} className={style["user-layout"]}>
        <UserSider location={this.props.location} />
        <Layout style={{ overflow: "auto" }}>
          <Header
            style={{ background: "#fff", textAlign: "center", padding: 0 }}
          >
            <div className={style.header}>
              <div
                className={style.header__item}
                onClick={() => {
                  history.push("/home");
                }}
              >
                返回首页
              </div>
              <div className={style.header__item}>{phone}</div>
              <div className={style.header__item} onClick={this.handleLogout}>
                退出
              </div>
            </div>
          </Header>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              height: "100%"
            }}
          >
            {renderRoutes(route.routes)}
          </Content>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.login.userInfo
  };
};

const mapDispatchToProps = dispatch => ({
  clearnUserInfo: () => dispatch(actionCreators.clearUserInfo())
});

export default connect(mapStateToProps, mapDispatchToProps)(SiderDemo);
