import React from "react";
import { Layout, Menu } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import history from "../utils/history";
import routes from "../routes/index";

import style from "./user-sider.module.scss";

const { Sider } = Layout;
const { SubMenu } = Menu;

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.selectedKeyMap = {};
    this.openKeysMap = {};
    this.state = {
      selectedKeys: [],
      openKeys: [],
      collapsed: false
    };
  }
  componentDidMount() {
    const {
      location: { pathname }
    } = this.props;
    this.setState({
      selectedKeys: this.selectedKeyMap[pathname],
      openKeys: this.openKeysMap[pathname]
    });
  }
  componentDidUpdate(prevProps) {
    const {
      location: { pathname }
    } = this.props;
    if (prevProps.location.pathname !== pathname) {
      this.setState({
        selectedKeys: this.selectedKeyMap[pathname],
        openKeys: this.openKeysMap[pathname]
      });
    }
  }
  handleClickItem = ({ keyPath }) => {
    const path = keyPath[0];
    history.push(path);
    this.setState({
      selectedKeys: this.selectedKeyMap[path],
      openKeys: this.openKeysMap[path]
    });
  };

  renderItem = (routes = [], parentKeys = [], selectedKey) => {
    let menuItems = [];
    routes.forEach((v, k) => {
      if (v.name && !v.hideInUser) {
        this.openKeysMap[v.path] = parentKeys;
        this.selectedKeyMap[v.path] = [selectedKey || v.path];
        if (!v.routes) {
          const Item = (
            <Menu.Item key={v.path}>
              <span>{v.name}</span>
            </Menu.Item>
          );
          menuItems.push(Item);
        } else if (v.routes) {
          const SubItem = (
            <SubMenu key={v.path} title={<span>{v.name}</span>}>
              {this.renderItem(v.routes, [...parentKeys, v.path])}
            </SubMenu>
          );
          menuItems.push(SubItem);
        }
      } else if (!v.name && v.routes && !v.hideInUser) {
        menuItems = this.renderItem(v.routes, [...parentKeys, v.path]);
      }
    });
    return menuItems;
  };

  onOpenChange = openKeys => {
    console.log(openKeys);
    this.setState({ openKeys });
    // const latestOpenKey = openKeys.find(
    //   key => this.state.openKeys.indexOf(key) === -1
    // );
    // if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //   this.setState({ openKeys });
    // } else {
    //   this.setState({
    //     openKeys: latestOpenKey ? [latestOpenKey] : []
    //   });
    // }
  };

  onChangeMenu = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  render() {
    const Items = this.renderItem(routes);
    const { selectedKeys, openKeys, collapsed } = this.state;

    return (
      <Sider
        className={style["user-sider"]}
        width={256}
        style={{ height: "100vh" }}
        collapsible
        collapsed={collapsed}
        theme='dark'
      >
        <div className={style.menu} onClick={this.onChangeMenu}>
          {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
        </div>
        <div className={style.logo} />
        <Menu
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={this.onOpenChange}
          theme='dark'
          mode='inline'
          onClick={this.handleClickItem}
        >
          {Items}
        </Menu>
      </Sider>
    );
  }
}
