import React from "react";

import style from "./footer.module.scss";
import footerLogo from "../assets/footer-logo.png";
import qrcode from "../assets/qrcode.png";
import letter from "../assets/letter.png";

export default () => {
  return (
    <div className={style.footer}>
      <div className={style.ct}>
        <div className={style.logo}>
          <img src={footerLogo} alt='' />
        </div>
        <div className={style.info}>
          <div className={style.lf}>
            <div>合作交流</div>
            <div>关于我们</div>
            <div style={{ borderRight: "none" }}>加入我们</div>
          </div>
          <div className={style.email}>
            <img src={letter} alt='' />
            邮箱 ：3360973080qq.com
          </div>
          <div style={{ paddingLeft: 10 }}>
            版权所有：海南和家健康科技有限公司 https://www.hnhjjk.com/ &nbsp;&nbsp;&nbsp;
            <span className={style.link} style={{ cursor: 'pointer' }} onClick={() => {
              window.open('https://beian.miit.gov.cn/#/Integrated/index')
            }}>ICP备18003320号-2</span>
          </div>
        </div>
        <div className={style.qrcode}>
          <img src={qrcode} alt='' />
          <p>关注和家健康</p>
        </div>
      </div>
    </div>
  );
};
