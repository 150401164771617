import React from "react";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import { PersistGate } from "redux-persist/lib/integration/react";
import "bootstrap/dist/css/bootstrap.css";
import routes from "./routes/index";
import store from "./store/index";
import { persistor } from "./store/index";

import "./App.css";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter className='App'>{renderRoutes(routes)}</HashRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
