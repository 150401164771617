import React from "react";
import NavBar from "./NavBar";

import style from "./header.module.scss";

export default () => {
  return (
    <div className={style.header}>
      <NavBar />
    </div>
  );
};
