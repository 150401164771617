import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  START_LOGIN,
  UPDATE_TOKEN,
  CLEAR_USER_INFO,
  UPDATE_USER_INFO
} from "./contants";

const initialData = {
  loading: false,
  userInfo: {
    token: "",
    user: {}
  }
};

const reducer = (state = initialData, action) => {
  switch (action.type) {
    case START_LOGIN:
      return {
        ...state,
        loading: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        loading: false
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false
      };
    case UPDATE_TOKEN:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          token: action.token
        }
      };
    case CLEAR_USER_INFO:
      return {
        ...state,
        userInfo: {
          token: "",
          user: {}
        }
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: {
          token: state.userInfo.token,
          user: {
            ...state.userInfo.user,
            ...action.payload
          }
        }
      };
    default:
      return state;
  }
};

export default reducer;
