// import React from "react";
import axios from "axios";
import { message } from "antd";
import store from "../store/index";

const PROD = process.env.NODE_ENV === "production";
const getToken = function() {
  const state = store.getState();
  const {
    login: { userInfo }
  } = state;
  const token = userInfo && userInfo.token;
  if (token) {
    return token;
  }
  return null;
};

function request(contentType = "application/x-www-form-urlencoded") {
  const instance = axios.create({
    baseURL: PROD ? `${window.location.origin}/api` : undefined
  });
  instance.defaults.headers.post["Content-Type"] = contentType;
  instance.interceptors.request.use(
    function(config) {
      const token = getToken();
      if (token) {
        const newConfig = { ...config, headers: { ...config.headers, token } };
        return newConfig;
      }
      return config;
    },
    function(error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    }
  );
  instance.interceptors.response.use(function(response) {
    const data = response.data;
    if (data.code >= 200 && data.code < 300) {
      return response;
    } else {
      message.error(data.msg);
      return response;
    }
  });
  return instance;
  // return axios(options)
  //   .then(res => {
  //     return res;
  //   })
  //   .catch(error => {
  //     const {
  //       response: { status, statusText }
  //     } = error;
  //     notification.error({
  //       message: (
  //         <div>
  //           请求错误 <span style={{ color: "red" }}>{status}</span> :{" "}
  //           {options.url}
  //         </div>
  //       ),
  //       description: statusText,
  //       onClick: () => {
  //         console.log("Notification Clicked!");
  //       }
  //     });
  //     return Promise.reject(error);
  //   });
}

export default request;
